
.menuEditorBreadcrumbs {
    flex-grow: 1;

    margin-right: 12px;

    display: flex;
    flex-direction: row;

    color: gray;
    font-weight: 500;
    font-size: 14px;
}

.menuEditorBreadcrumbs .currentCrumb {
    color: #101010;
}

.menuItemDragHandle  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    color: silver;
    width: 48px;
}

.menuItemDragHandle svg {
    transform: scale(2);
}

.menuItemScreen {
    width: 160px;
    padding: 4px;
    position: relative;
    flex-shrink: 0;
    background-color: black;
    margin: 12px;
    border-radius: 6px;
}


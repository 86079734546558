.screenEditor {
    border: 1px solid silver;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}
  
.screenEditor .toolbarsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.screenEditor .toolbarsContainer .toolSelectorsGroup {
    display: grid;
    grid-template-columns: 60px 60px;
    grid-template-rows: 60px 60px 60px 60px;
}

.screenEditor .toolbarsContainer .drawingModeGroup {
    display: grid;
    grid-template-columns: 60px 60px;
    grid-template-rows: 40px;
}

.screenEditor .speedDialDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.screenEditor .toolButton svg {
    height: 48px;
}

@media screen and (max-width: 700px) {
    .screenEditor {
        flex-direction: column !important;
    }

    .screenEditor .toolbarsContainer .toolSelectorsGroup {
        grid-template-rows: 60px 60px;
        grid-template-columns: 60px 60px 60px 60px;
    }

    .screenEditor .toolbarsContainer .drawingModeGroup {
        display: grid;
        grid-template-rows: 60px 60px;
        grid-template-columns: 60px;
    }
    
    .screenEditor .toolbarsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }    
}

.urlCommandParams {
    display: flex;
    flex-direction: column;    
    height: 100%;
}

.urlCommandParams .urlAndMethod {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.urlCommandParams .urlAndMethod .urlField {
    flex-grow: 1;
    margin-left: 16px;
}

.urlCommandParams .urlAndMethod .methodField {
    width: 80pt;
}

.urlCommandParams .bodyField {
    margin-top: 16px;
    flex-grow: 1;
}

.urlCommandParams .bodyField .bodyInput {
    font-family: monospace;
    font-size: 10pt;
}

.headersList .listTitle {
    display: flex;
    align-items: center;
}

.headersList .listTitle h1 {
    font-weight: normal;
    font-size: 14pt;
    flex-grow: 1;
}

.headersList .headerEditor {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headersList .headerEditor input {
    border: solid 1px rgb(224, 224, 224);
    font-size: 12pt;
    padding: 6px;
    flex-grow: 1;
}

.headersList .headerEditor .deleteHeaderButton {
    visibility: hidden;
}

.headersList .headerEditor:hover .deleteHeaderButton {
    visibility: visible;
}
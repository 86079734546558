.pushButtonCommandParams {
    display: flex;
    flex-direction: column;    
    height: 100%;
}

.pushButtonCommandParams .deviceField {
    margin-top: 16px;
}

.pushButtonCommandParams .buttonNameField {
    margin-top: 16px;
}


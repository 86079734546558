.App {
  text-align: center;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppRoot {
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.AccountIndicator {
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AppTitle {
  display: flex;
  flex-direction: row;

  align-items: center;
  background-color: black;
  color: rgb(230, 230,230);
  font-size: 48px;
  font-weight: 500;
  height: 96px;
  padding-left: 24px;
  padding-right: 24px;
}

.AppBody {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.AppTitle .SiteName {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 32px;
}

.ApplicationSubHeader {
  background: rgb(40, 40, 40);

  box-shadow: 0 12px 32px -16px hsla(0, 0%, 0%, 1);
}

.SystemSelector {
  float: right;
  color: white;
  text-align: right;
  padding: 8px;
}

@media screen and (max-width: 700px) {
  .SystemSelector {
    float: none !important;
  }
}

.AppNavigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-top: 8px;
  padding-bottom: 8px;
}

.AppNavigation .NavItem {
  display: block;

  text-decoration: none;

  color: rgb(255, 161, 41);
  font-weight: 500;
  margin-left: 24px;
  margin-right: 24px;

  padding: 3px;
  border-radius: 3px;
}

.AppNavigation .NavItem.active {
  color: #282828;
  background: orange;
}

.AppNavigation .Spacer {
  flex-grow: 1;
}

.AppMain {
  flex-grow: 1;
  margin-top: 16px;
}


.App-link {
  color: #61dafb;
}

code {
  word-wrap: break-word;
}


.bigListTitleBar {
    align-items: center;
    border-bottom: 1px solid rgba(207, 207, 207, 0.34);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 4px 12px;
}

.bigList {
    
}

.bigList .listRow {
    background: #fff;
    display: flex;
    flex-direction: row;
    height: 128px;    
    border-bottom: 1px solid rgb(228, 228, 228);
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
}

.listRow .titleAndDescContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding-left: 20px;
    overflow-x: hidden;
}

.listRow .title {
    font-size: 24px;
    color: rgb(96, 96, 96);
    text-align: left;
    font-weight: 500;
    margin-bottom: 4px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.listRow .desc {
    text-align: left;
    color: rgb(142, 142, 142);
    font-size: 14px;

    text-overflow: ellipsis;
    overflow: hidden;
}

.listRow .descAndActions {
    display: flex;
    align-items: center;
}

.listRow .actions {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;    
    flex-grow: 0;
    padding-right: 16px;
}

.listRow .actions .action {
    margin-right: 12px;
 }
 
.listRow .actions .action a {
   color: rgb(142, 142, 142);
}


.listRow:hover .actions {
    visibility: visible;
}


.editButton {
    /* background-color: rgb(224, 224, 224);
     border-radius: 3px;
     border: solid 1px gray;    */
     visibility: hidden;
 }
 
 div:hover > .editButton {
     visibility: visible;    
 }
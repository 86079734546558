.inlineEditableLabel {
    display: flex;
    flex-direction: row;
}

.inlineEditableLabel input {
    border: 0;
    margin: 0;
    padding: 0;
}

.inlineEditableLabel .inlineEditableLabelNonEdited {    
    border: 0;
    margin: 0;
    padding: 0;
}
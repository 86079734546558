
.deviceAddDialog {
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deviceAddDialog .deviceToken {
    font-size: 24px;
    text-align: center;
}

.deviceAddDialog .regularContent {
    flex-grow: 1;
    width:100%;
}

.deviceAddDialog .centerAlignedContent {
    flex-grow: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.deviceAddDialog .heading {
    font-size: 14pt;
    width: 100%;
}

.deviceAddDialog .multiField {
    display: flex;
    align-items: center;
    justify-items: center;
}

.deviceAddDialog .inlineError {
    color: red;
}
.screenTitleBar {
  border-bottom: 1px solid rgba(207, 207, 207, 0.34);
  display: grid;

  grid-template-columns: 1fr;
  grid-template-areas: 
    'breadcrumbs actions'
    'title actions';

  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 48px;
}

.screenTitleBar .titleText {
  margin-left: 8px;
  grid-area: title;
  font-size: 24px;
  font-weight: 500;
}
  
.screenTitleBar .actionBar {  
  margin-left: 8px;
  grid-area: actions;
  display: flex;
  flex-direction: row;
}

.screenTitleBar .breadCrumbs {
  grid-area: breadcrumbs;
  color: #A0A0A0;
  margin-left: 8px;
  font-size: 12px;
}
  
@media screen and (max-width: 700px) {
  .screenTitleBar {
    grid-template-areas: 
      'breadcrumbs'
      'title'
      'actions';
  }

  .screenTitleBar .actionBar {  
    justify-content: flex-end;
  }
}
